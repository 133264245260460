:root {
  --background-alternate-color: #fff;
  --background-color: #f4f4f4;
  --foreground-color: #000;
  --light-border-color: #eaeaea;
  --clickable-color: #196fc4;
  --clickable-highlight-color: #237dd7;
  --error-color: #9d081d;
  --error-background-color: #ffe0e5;
  --success-color: #0c8f07;
  --success-background-color: #ddf7dc;
  --disabled-control-background-color: #eaeaea;
  --primary-button-background-color: var(--clickable-color);
  --primary-button-background-highlight-color: var(--clickable-highlight-color);
  --primary-button-color: #fff;
  --light-button-color: var(--clickable-color);
  --light-button-highlight-color: var(--clickable-highlight-color);
  --disabled-control-color: #4d4d4d;
  --footer-background-color: #1e1e1e;
  --footer-clickable-color: #429efa;
  --footer-clickable-highlight-color: #59acff;
  --footer-foreground-color: #e2e2e3;
	--color-negative-foreground-1: #4f1a47;
	--color-negative-foreground-2: #8a4880;
	--color-negative-background-1: #fde6fd;
	--color-warning-foreground-1: #4f261a;
	--color-warning-foreground-2: #8a5d48;
	--color-warning-background-1: #fdfde6;
	--color-success-foreground-1: #284f1a;
	--color-success-foreground-2: #608a48;
	--color-success-background-1: #f2fde6;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #1b1b1b;
    --background-alternate-color: #242424;
    --foreground-color: #f5f5f5;
    --light-border-color: #2b2b2b;
    --clickable-color: #429efa;
    --clickable-highlight-color: #59acff;
    --error-color: #ec8d77;
    --error-background-color: #423333;
    --success-color: #a0d09e;
    --success-background-color: #213c20;
    --disabled-control-background-color: #eaeaea;
    --primary-button-background-color: #196fc4;
    --primary-button-background-highlight-color: #237dd7;
    --primary-button-color: #fff;
    --disabled-control-color: #4d4d4d;
  }
}
