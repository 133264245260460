.button,
a.button {
	text-align: center;
	cursor: pointer;
	display: inline-block;
	padding: 0.5em 1em;
	margin: 0;
	border: 1px solid var(--primary-button-background-color);
	background-color: var(--primary-button-background-color);
	color: var(--primary-button-color);
	text-decoration: none;

	&:hover,
	&:focus {
		background-color: var(--primary-button-background-highlight-color);
	}

	&:disabled,
	&:disabled:hover {
		background-color: var(--primary-button-background-color);
		filter: grayscale(1);
		cursor: not-allowed;
	}

	&.light {
		background-color: transparent;
		background-image: none;
		color: var(--light-button-color);

		&:hover,
		&:focus {
			color: var(--light-button-highlight-color);
			border-color: var(--light-button-highlight-color);
		}
	}

	&.link {
		background-color: transparent;
		background-image: none;
		padding: 0;
		text-align: inherit;
		margin: 0;
		border: none;
		box-shadow: none;
		color: var(--light-button-color);

		&:hover,
		&:focus {
			color: var(--light-button-highlight-color);
		}
	}

	&.no-style {
		border: none;
		background-color: transparent;
		background-image: none;
		padding: 0;
		color: inherit;

		&.clickable-color-in-foreground {
			color: var(--primary-button-background-color);

			&:hover, &:focus {
				color: var(--primary-button-background-highlight-color);
			}
		}
	}

	&.char-circle {
		padding: 0;
		width: 2em;
		height: 2em;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1em;

		& > .fas {
			margin-right: 1px;
		}
	}

	&.no-box-shadow {
		box-shadow: none;
	}

	&.text-shadow {
		text-shadow: 1px 1px #dedede;
		transition: text-shadow 200ms;

		&:hover {
			text-shadow: 2px 2px #dedede;
		}
	}
}
