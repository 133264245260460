.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.font-size-1_33 {
	font-size: 1.33em;
}

.pre-wrap {
	white-space: pre-wrap;
}
