.m-auto {
	margin: auto;
}

.mt-1 {
	margin-top: 1em;
}

.mr-1 {
	margin-right: 1em;
}

.mb-1 {
	margin-bottom: 1em;
}

.ml-1 {
	margin-left: 1em;
}

.ml-0p5-important {
	margin-left: 0.5em !important;
}
