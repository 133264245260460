body {
	font-family: 'Source Sans Pro', sans-serif;
	background-color: var(--background-color);
	color: var(--foreground-color);
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
	margin-top: 0;
	margin-bottom: 0;
}

button,
input,
label,
textarea,
select,
option {
	font-size: inherit;
	font-family: inherit;
	color: inherit;
	background-color: inherit;
}

@media(prefers-color-scheme: dark) {
	option {
		/* Fix issue where inherit background-color is ignored */
		background-color: var(--background-alternate-color);
	}
}

a,
a:visited {
	color: var(--clickable-color);
	text-decoration: none;
}

a:hover, a:active {
	color: var(--clickable-highlight-color);
}
