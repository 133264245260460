.show-in-light-mode {
	display: initial;
}

.show-in-dark-mode {
	display: none;
}

@media (prefers-color-scheme: dark) {
	.show-in-dark-mode {
		display: initial;
	}
	.show-in-light-mode {
		display: none;
	}
}
