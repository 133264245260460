@import 'margins';
.block {
	display: block;
}
.grid {
	display: grid;
}
.inline-block {
	display: inline-block;
}
.display-none {
	display: none;
}

.flex-column {
	flex-direction: column;
}
.flex-center-center {
	justify-content: center;
	align-items: center;
}
.space-between {
	justify-content: space-between;
}

/* Margin */
.mlauto {
	margin-left: auto;
}
.mrauto {
	margin-right: auto;
}
.mxauto {
	margin-left: auto;
	margin-right: auto;
}
.my1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.mt_5 {
	margin-top: 0.5rem;
}
.mt_75 {
	margin-top: 0.75rem;
}
.mt-1 {
	margin-top: 1rem;
}
.mb_5 {
	margin-bottom: 0.5rem;
}
.mb-1 {
	margin-bottom: 1rem;
}
.mb3 {
	margin-bottom: 3rem;
}

.mb-1em {
	margin-bottom: 1em;
}
.mr_3em {
	margin-right: 0.3em;
}
.mt_5em {
	margin-top: 0.5em;
}
.mb_5em {
	margin-bottom: 0.5em;
}
.mx_5em {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

/* Padding */
.pt1 {
	padding-top: 1rem;
}
.pb-1 {
	padding-bottom: 1rem;
}
.pb2 {
	padding-bottom: 2rem;
}
.pb_5 {
	padding-bottom: 0.5rem;
}

.flex {
	display: flex;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-grow {
	flex-grow: 1;
}

.justify-content-center {
	justify-content: center;
}

.content-wrapper {
	padding-left: $siteGutterWidthSmall;
	padding-right: $siteGutterWidthSmall;
}

@media (min-width: $medium) {
	.content-wrapper {
		padding-left: $siteGutterWidthMedium;
		padding-right: $siteGutterWidthMedium;
	}
}

@media (min-width: $large) {
	.content-wrapper {
		padding-left: $siteGutterWidthLarge;
		padding-right: $siteGutterWidthLarge;
	}
}

.site-content-wrapper {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 5.6rem);
	overflow: auto;
}

@media (min-width: $large) {
	.site-content-wrapper {
		height: calc(100vh - 5.1rem);
		overflow: auto;
	}
}

.elevated-1 {
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}
